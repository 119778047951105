import { useSetRecoilState } from "recoil";
import NylasAPI from "../../axios/axios-instance";
import { contactState } from "../atom/contactState";

export { useContactActions };

function useContactActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/contacts`;
  const setContactState = useSetRecoilState(contactState);

  async function getContacts(offset) {
    try {
      const { data } = await NylasAPI.get(`${baseUrl}`);
      setContactState(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function createContact({
    givenName,
    surname,
    emailAddress,
    phoneNumber,
  }) {
    try {
      await NylasAPI.post(`${baseUrl}`, {
        givenName,
        surname,
        emailAddresses: [{ email: emailAddress, type: "personal" }],
        phoneNumbers: [{ number: phoneNumber, type: "personal" }],
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteContact(contactId) {
    let success = false;
    try {
      await NylasAPI.delete(`${baseUrl}/${contactId}`);
      success = true;
    } catch (error) {
      success = false;
    }
    return success;
  }

  async function updateContact(
    { givenName, surname, emailAddress, phoneNumber },
    id
  ) {
    try {
      await NylasAPI.put(`${baseUrl}/${id}`, {
        givenName,
        surname,
        emailAddresses: [{ email: emailAddress, type: "personal" }],
        phoneNumbers: [{ number: phoneNumber, type: "personal" }],
      });
      setContactState([]);
    } catch (error) {
      console.log(error);
    }
  }

  return { getContacts, updateContact, createContact, deleteContact };
}
