import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Box, LinearProgress } from "@mui/material";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import Calendar from "@toast-ui/react-calendar";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import CalendarDialog from "../Components/CalendarUI/calendarDialog";
import withNavBar from "../hooks/withNavBar";
import { useCalendarActions } from "../recoil/actions/calendarActions";
import { calendarState } from "../recoil/atom/calendarState";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    width: "100%",
    margin: "5px",
    "& > button": {
      color: "#fff",
      backgroundColor: "#4169e1",
      margin: "2px;",
    },
  },
  addEvent: {
    backgroundColor: "#4169e1",
    width: "20%",
    margin: "10px",
    color: "#fff",
  },
}));

function NylasCalendar() {
  const { getCalendars } = useCalendarActions();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { calendar, events } = useRecoilValue(calendarState);
  const calendarRef = useRef(null);
  const [open, setOpenDialog] = useState(false);
  const [eventUpdate, setEventUpdate] = useState(undefined);
  const [date, setDate] = useState({
    starts_after: null,
    starts_before: null,
  });

  useEffect(() => {
    if (calendarRef.current) {
      (async () => {
        try {
          setLoading(true);
          await getCalendars({
            starts_after: date.starts_after,
            starts_before: date.starts_before,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [date]);

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    if (!calendarInstance) {
      return;
    }

    calendarInstance.next();
    setDate({
      starts_after: Math.floor(
        calendarInstance.renderRange.start.d.d.getTime() / 1000
      ),
      starts_before: Math.floor(
        calendarInstance.renderRange.end.d.d.getTime() / 1000
      ),
    });
  };

  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    if (!calendarInstance) {
      return;
    }

    calendarInstance.prev();

    setDate({
      starts_after: Math.floor(
        calendarInstance.renderRange.start.d.d.getTime() / 1000
      ),
      starts_before: Math.floor(
        calendarInstance.renderRange.end.d.d.getTime() / 1000
      ),
    });
  };

  const handleToday = () => {
    const calendarInstance = calendarRef.current.getInstance();

    if (!calendarInstance) {
      return;
    }
    calendarInstance.today();

    setDate({
      starts_after: Math.floor(
        calendarInstance.renderRange.start.d.d.getTime() / 1000
      ),
      starts_before: Math.floor(
        calendarInstance.renderRange.end.d.d.getTime() / 1000
      ),
    });
  };

  console.dir(date);
  console.dir(calendarRef.current?.getInstance());
  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" color="primary" onClick={handleToday}>
          Today
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickPrevButton}
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickNextButton}
        >
          &gt;
        </Button>
        <Button
          className={classes.addEvent}
          size="medium"
          variant="contained"
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <AddIcon />
        </Button>
      </div>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div>
        <Calendar
          ref={calendarRef}
          height="900px"
          gridSelection={true}
          calendars={
            calendar
              ? [
                  {
                    ...calendar,
                    backgroundColor: "#4169e1",
                    borderColor: "#4169e1",
                  },
                ]
              : []
          }
          disableDblClick={true}
          disableClick={false}
          isReadOnly={false}
          month={{ startDayOfWeek: 0 }}
          events={events || []}
          useDetailPopup={false}
          usageStatistics={false}
          week={{
            scheduleView: ["time"],
            taskView: false,
          }}
          useFormPopup={false}
          onClickEvent={(eventInfo) => {
            setEventUpdate(eventInfo.event);
            setOpenDialog(true);
          }}
          view={"week"} // You can also set the `defaultView` option.
        />
      </div>
      {open && (
        <CalendarDialog
          calendarId={calendar.id}
          open={open}
          event={eventUpdate}
          onClose={() => {
            setOpenDialog(false);
            setEventUpdate(undefined);
          }}
        />
      )}
    </div>
  );
}

export default withNavBar(NylasCalendar);
