import { TextField } from "@material-ui/core";
import { useCallback } from "react";
import useTextValue from "../../hooks/useTextValue";
import { useContactActions } from "../../recoil/actions/contactActions";
import NylasDialog from "../Dialog/Dialog";

export default function ContactDialog({ open, onClose, contact }) {
  const [givenName, onGivenNameChange] = useTextValue(
    contact?.given_name || ""
  );
  const [surname, onSurNameChange] = useTextValue(contact?.surname || "");
  const [emailAddress, onEmailAddress] = useTextValue(
    contact?.emails.at(0)?.email || ""
  );
  const [phoneNumber, onPhoneNumber] = useTextValue(
    contact?.phone_numbers.at(0)?.number || ""
  );
  const { updateContact, createContact, getContacts } = useContactActions();

  const onSubmit = useCallback(async () => {
    if (contact) {
      await updateContact(
        { givenName, surname, emailAddress, phoneNumber },
        contact.id
      );
    } else {
      await createContact({ givenName, surname, emailAddress, phoneNumber });
    }

    await getContacts();
    onClose();
  }, [givenName, surname, emailAddress, phoneNumber]);

  return (
    <>
      {open && (
        <NylasDialog
          open={open}
          onClose={onClose}
          title={contact ? "Update Contact" : "Create Contact"}
          update={!!contact}
          onSubmit={onSubmit}
        >
          <TextField
            autoFocus
            margin="dense"
            id="givenName"
            label="Given Name"
            type="text"
            fullWidth
            variant="standard"
            value={givenName}
            onChange={onGivenNameChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="surname"
            label="Surname"
            type="text"
            fullWidth
            variant="standard"
            value={surname}
            onChange={onSurNameChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="emailAddress"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={emailAddress}
            onChange={onEmailAddress}
          />
          <TextField
            autoFocus
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="text"
            fullWidth
            variant="standard"
            value={phoneNumber}
            onChange={onPhoneNumber}
          />
        </NylasDialog>
      )}
    </>
  );
}
