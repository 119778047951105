import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles((theme) => ({
  emailDivRead: {
    width: "100%",
    height: "60px",
    backgroundColor: "#D3D3D3",
    border: "1px solid black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailDivUnread: {
    width: "100%",
    height: "60px",
    backgroundColor: "white",
    border: "1px solid black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    fontSize: "1rem",
    padding: "0px 10px",
    whiteSpace: "nowrap",
    width: "15%",
  },
  emailWrapper: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  subject: {
    fontSize: "1rem",
    padding: "0px 10px",
  },
  snippet: {
    frontSize: "1rem",
    color: "gray",
    overflowX: "hidden",
    padding: "0px 10px",
  },
  timestamp: {
    padding: "0px 10px",
    whiteSpace: "nowrap",
  },
  link: {
    textDecoration: "none",
    width: "65%",
    color: "black",
  },
}));

function EmailBar({
  email,
  check = false,
  unselectedOne = false,
  selectClicked,
}) {
  const [checked, setChecked] = useState(check);

  useEffect(() => {
    if (check) {
      setChecked(check);
    } else if (!check && !checked) {
      setChecked(false);
    } else if (!check && selectClicked) {
      setChecked(check);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  const updateChecked = (event) => {
    setChecked(event.target.checked);
    if (check === true) {
      unselectedOne();
    }
  };

  const classes = useStyles();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: tz,
  };
  const date = new Intl.DateTimeFormat("en-US", options).format(
    new Date(email["last_message_timestamp"] * 1000)
  );
  const clickRoute = "/email/" + email["id"];

  const emailParticipant = email?.participants?.[0]?.name
    ? email?.participants?.[0]?.name
    : email?.participants?.at(0)?.email || "";
  return (
    <div
      className={
        email["unread"] ? classes.emailDivUnread : classes.emailDivRead
      }
    >
      <Checkbox {...label} checked={checked} onChange={updateChecked} />
      <p className={classes.name}> {emailParticipant}</p>
      <Link to={clickRoute} className={classes.link}>
        <div className={classes.emailWrapper}>
          <p className={classes.subject}> {email["subject"]} </p>
          <p className={classes.snippet}> {email["snippet"]} </p>
        </div>
      </Link>
      <p className={classes.timestamp}> {date} </p>
    </div>
  );
}

export default EmailBar;
