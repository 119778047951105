import { Button, Chip, TextField } from "@material-ui/core";
import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import useTextValue from "../../hooks/useTextValue";
import { useCalendarActions } from "../../recoil/actions/calendarActions";
import NylasDialog from "../Dialog/Dialog";

export default function CalendarDialog({
  open = false,
  onClose,
  event,
  calendarId,
}) {
  const [subject, onSubjectChange] = useTextValue(event?.title || "");
  const [participants, onParticipantsChange] = useTextValue();
  const [participantList, onSetParticipantList] = useState(
    event?.attendees?.map((particp) => particp.email) || []
  );
  const [startTime, setStartTimeChange] = useState(
    event ? new Date(event.start) : new Date()
  );
  const [endTime, setEndTimeChange] = useState(
    event ? new Date(event.end) : new Date()
  );

  const { createEvent, updateEvent, deleteEvent } = useCalendarActions();

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSetParticipantList((list) => {
        if (list.includes(e.target.value)) {
          return list;
        }

        return [...list, e.target.value];
      });
    }
  };

  const onTimeChange = (type, newDate) => {
    if (type === "start") {
      setStartTimeChange(newDate);
    } else {
      setEndTimeChange(newDate);
    }
  };

  const handleDelete = (e, participant) => {
    onSetParticipantList((list) =>
      list.filter((participant_) => participant_ !== participant)
    );
  };

  const onSubmit = async (e) => {
    const startTime_ = Math.floor(startTime.getTime() / 1000);
    const endTime_ = Math.floor(endTime.getTime() / 1000);

    if (event) {
      await updateEvent(event.id, {
        calendarId,
        subject,
        busy: true,
        participants: participantList.map((participant) => ({
          email: participant,
          name: "",
        })),
        startTime: startTime_,
        endTime: endTime_,
      });
    } else {
      await createEvent({
        calendarId,
        subject,
        busy: true,
        participants: participantList.map((participant) => ({
          email: participant,
          name: "",
        })),
        startTime: startTime_,
        endTime: endTime_,
      });
    }
    onClose();
  };

  return (
    <>
      {open && (
        <NylasDialog
          open={open}
          onClose={onClose}
          onSubmit={onSubmit}
          title={event ? "Update Event" : "Create Event"}
          update={!!event}
          DeleteAction={
            event && (
              <Button
                variant={"outlined"}
                style={{
                  color: "red",
                }}
                onClick={async (e) => {
                  await deleteEvent(event.id);
                  onClose();
                }}
              >
                {"Delete"}
              </Button>
            )
          }
        >
          <Stack spacing={2}>
            <TextField
              autoFocus
              margin="dense"
              id="subject"
              label="Subject"
              type="text"
              fullWidth
              variant="standard"
              value={subject}
              onChange={onSubjectChange}
            />
            <TextField
              autoFocus
              margin="dense"
              id="participant"
              label="Participants"
              type="text"
              fullWidth
              variant="standard"
              value={participants}
              onChange={onParticipantsChange}
              onKeyDown={onKeyDown}
            />
            {participantList.map((participant) => (
              <Chip
                style={{
                  backgroundColor: "#4169e1",
                  color: "#fff",
                  width: "50%",
                }}
                label={participant}
                onDelete={(e) => handleDelete(e, participant)}
              />
            ))}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Time"
                value={startTime}
                onChange={(value) => onTimeChange("start", value)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                label="End Time"
                value={endTime}
                onChange={(value) => onTimeChange("end", value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        </NylasDialog>
      )}
    </>
  );
}
