import { useSetRecoilState } from "recoil";
import NylasAPI from "../../axios/axios-instance";
import { schedulerState } from "../atom/schedulerState";

export function useSchedulerActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/scheduler`;
  const setSchedulerState = useSetRecoilState(schedulerState);

  async function getSchedulerPages() {
    try {
      const { data } = await NylasAPI.get(`${baseUrl}`);
      setSchedulerState(({ calendars }) => {
        return {
          calendars,
          pages: data,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function createSchedulerPages({ calendarId, slug, title, eventTitle }) {
    try {
      await NylasAPI.post(`${baseUrl}`, {
        calendarId,
        slug,
        title,
        eventTitle,
      });
      await getSchedulerPages();
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteScheduler(pageId) {
    try {
      const { data: calendars } = await NylasAPI.delete(`${baseUrl}/${pageId}`);
      await getSchedulerPages();
    } catch (error) {
      console.log(error);
    }
  }

  async function getCalendars() {
    try {
      const { data: calendars } = await NylasAPI.get(`${baseUrl}/calendars`);
      setSchedulerState(({ pages }) => {
        return {
          calendars,
          pages,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  return {
    createSchedulerPages,
    getSchedulerPages,
    getCalendars,
    deleteScheduler,
  };
}
