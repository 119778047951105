import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ca } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import useTextValue from "../../hooks/useTextValue";
import Calendar from "../../Pages/Calendar";
import { useSchedulerActions } from "../../recoil/actions/schedulerActions";
import NylasDialog from "../Dialog/Dialog";

export default function SchedulerDialog({ open, onClose, calendars }) {
  const [slug, setSlug] = useTextValue("");
  const [eventTitle, setEventTitle] = useTextValue("");
  const [name, setName] = useTextValue("");
  const [calendarId, setCalendarId] = useTextValue("");
  const { createSchedulerPages } = useSchedulerActions();
  const [openPage, setOpenPage] = useState({
    open: false,
    url: "",
  });

  const handleCalendarChange = (e) => {
    setCalendarId(e);
  };

  const onSubmit = useCallback(async () => {
    await createSchedulerPages({
      calendarId,
      eventTitle,
      slug,
      title: name,
    });
    onClose();
  }, [slug, eventTitle, name, calendarId]);

  return (
    <>
      {open && (
        <NylasDialog
          open={open}
          onClose={onClose}
          title={"Create Scheduler"}
          update={false}
          onSubmit={onSubmit}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Page Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={setName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="eventTitle"
            label="Event Title"
            type="text"
            fullWidth
            variant="standard"
            value={eventTitle}
            onChange={setEventTitle}
          />
          <TextField
            autoFocus
            margin="dense"
            id="slug"
            label="Page Slug"
            type="text"
            fullWidth
            variant="standard"
            value={slug}
            onChange={setSlug}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">
              Calendar
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={calendarId}
              onChange={handleCalendarChange}
              autoWidth
              label="Calendar"
            >
              {calendars?.map((calendar) => (
                <MenuItem value={calendar.id}>
                  <em>{calendar.name}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </NylasDialog>
      )}
    </>
  );
}
