import axios from "axios";
import { history } from "../history";
import {
  getSessionToken,
  removeSessionToken,
  setSessionToken,
} from "../session";

const NylasAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

NylasAPI.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const authValue = getSessionToken();

    if (authValue?.length > 0) {
      config.headers.Authorization = `Bearer ${authValue}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

NylasAPI.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.config.url.includes("auth")) {
      const token = response.data;
      setSessionToken(token);
    }

    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeSessionToken();
      history.push("/");
      return;
    }
    return Promise.reject(error);
  }
);

export default NylasAPI;
