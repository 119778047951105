import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  nylasLoader: {
    animation: "$pulse 0.6s ease-out infinite",
    width: "50%",
    height: "50%",
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 0.9,
      transform: "scale(0.8)",
    },
    "30%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "70%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0.9,
      transform: "scale(0.8)",
    },
  },
}));

export default function NylasLoader() {
  const styles = useStyles();
  return (
    <img
      className={styles.nylasLoader}
      src="https://www.nylas.com/wp-content/uploads/main-logo.svg"
    ></img>
  );
}
