import Navbar from "../Components/Navigation/navbar";

export default function withNavBar(Component) {
  return () => (
    <>
      <Navbar />
      <Component />
    </>
  );
}
