import { makeStyles } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import EmailBar from "../Components/EmailUI/emailBar";

//State Object for Emails
import { useRecoilValue } from "recoil";
import { emailState } from "../recoil/atom/emailState";

import withNavBar from "../hooks/withNavBar";
import { useEmailActions } from "../recoil/actions/emailActions";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles((theme) => ({
  mailBoxWrapper: {
    width: "95%",
    margin: "0 auto",
  },
}));

function Email(props) {
  // State and Class information
  const classes = useStyles();
  const emails = useRecoilValue(emailState);
  const emailActions = useEmailActions();

  // Use State data
  const [emailsChecked, addEmailsChecked] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [offset, setOffset] = useState(0);

  // Update state calls
  const updateChecked = (event) => {
    setSelectAll(event.target.checked);
  };

  const selectClicked = () => {
    setClicked(true);
  };

  const increment = () => {
    setOffset((prev) => prev + 10);
  };

  const decrement = () => {
    if (offset !== 0) {
      setOffset((prev) => prev - 10);
    }
  };

  useEffect(() => {
    emailActions.getThreads(0);
  }, []);

  return (
    <>
      {emails.length !== 0 ? (
        <div class={classes.mailBoxWrapper}>
          <Checkbox
            {...label}
            checked={selectAll}
            onChange={updateChecked}
            onClick={selectClicked}
          />
          <div>
            {emails.map((email) => {
              return (
                <EmailBar
                  key={email["id"]}
                  email={email}
                  check={selectAll}
                  selectClicked={clicked}
                  addEmailsChecked={addEmailsChecked}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <p>Pending Spinner</p>
        </div>
      )}
      <button onClick={decrement}>Back</button>
      <button onClick={increment}>Next</button>
    </>
  );
}

export default withNavBar(Email);
