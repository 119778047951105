import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import { useSchedulerActions } from "../../recoil/actions/schedulerActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#4169E1",
  },
}));

export default function SchedulerCard({ page, index, setOpenPage, ...props }) {
  const classes = useStyles();
  const { deleteScheduler } = useSchedulerActions();

  const openSchedulerEditor = (e) => {
    e.preventDefault();
    window.nylas.scheduler.show({
      auth: {
        pageEditToken: page.edit_token,
      },
    });
  };

  const deleteSchedulerPage = async (e) => {
    e.preventDefault();
    await deleteScheduler(page.id);
  };

  const schedulerUrl = `https://schedule.nylas.com/${page.slug}`;

  return (
    <Card className={classes.root}>
      <>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {index}
            </Avatar>
          }
          title={page.name}
          subheader={page.slug}
        />
        <CardActions disableSpacing>
          <IconButton onClick={openSchedulerEditor} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={(e) =>
              setOpenPage((page) => {
                return { open: true, url: schedulerUrl };
              })
            }
            aria-label="edit"
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={async (e) => await deleteSchedulerPage(e)}
            aria-label="Delete contact"
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </>
    </Card>
  );
}
