import { Box, Button, CircularProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SchedulerCard from "../Components/SchedulerUi/schedulerCard";
import withNavBar from "../hooks/withNavBar";
import { useSchedulerActions } from "../recoil/actions/schedulerActions";
import { schedulerState } from "../recoil/atom/schedulerState";
import SchedulerDialog from "../Components/SchedulerUi/schedulerDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  inline: {
    display: "flex",
  },
  addContact: {
    backgroundColor: "#4169e1",
    width: "20%",
    margin: "10px",
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  schedulerPage: {
    display: "flex",
    flexDirection: "column",
    width: "1000px",

    schedulerIframe: {
      height: "1000px",
      width: "1000px",
    },
  },
  header: {
    color: "#4169e1",
  },
}));

function Scheduler() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { pages, calendars } = useRecoilValue(schedulerState);
  const schedulerActions = useSchedulerActions();
  const [open, setOpenDialog] = useState(false);
  const [openPage, setOpenPage] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await schedulerActions.getSchedulerPages();
        await schedulerActions.getCalendars();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>Scheduler</h1>
      <Button
        className={classes.addContact}
        size="medium"
        variant="contained"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        <AddIcon />
      </Button>
      <Divider></Divider>
      {openPage.open ? (
        <>
          <div className={classes.schedulerPage}>
            <Button
              className={classes.addContact}
              size="small"
              variant="contained"
              onClick={() => {
                setOpenPage((page) => ({ open: false, url: "" }));
              }}
            >
              <CloseIcon />
            </Button>
            <iframe
              height="800px"
              width="1000px"
              className={classes.schedulerPage.schedulerIframe}
              src={openPage.url}
            ></iframe>
          </div>
        </>
      ) : (
        <>
          {" "}
          <List className={classes.root}>
            {!loading ? (
              pages.map((page, index) => {
                return (
                  <>
                    <ListItem key={index.toString()}>
                      <SchedulerCard
                        setOpenPage={setOpenPage}
                        page={page}
                        index={index}
                      />
                    </ListItem>
                    <Divider></Divider>
                  </>
                );
              })
            ) : (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </List>
        </>
      )}

      {open && (
        <SchedulerDialog
          open={open}
          calendars={calendars}
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </div>
  );
}

export default withNavBar(Scheduler);
