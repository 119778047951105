export function getSessionToken() {
  return sessionStorage.getItem("access_token");
}

export function setSessionToken(token) {
  return sessionStorage.setItem("access_token", token);
}

export function removeSessionToken() {
  return sessionStorage.removeItem("access_token");
}
