import { useSetRecoilState } from "recoil";

import NylasAPI from "../../axios/axios-instance";
import { emailState } from "../atom/emailState";

export { useEmailActions };

function useEmailActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/email`;
  const setEmailState = useSetRecoilState(emailState);

  return { getThreads };

  function getThreads(offset) {
    NylasAPI.get(`${baseUrl}?`, {
      params: {
        offsetAmount: offset,
      },
    })
      .then(function (response) {
        setEmailState(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
