import { useSetRecoilState } from "recoil";
import NylasAPI from "../../axios/axios-instance";
import { history } from "../../history";
import { authState } from "../atom/authState";
import { uiState } from "../atom/uiState";

export { useAuthActions };

function useAuthActions() {
  const setAuthState = useSetRecoilState(authState);
  const setUiState = useSetRecoilState(uiState);
  const API_BASE = process.env.REACT_APP_API_URL;

  async function getJWT(code) {
    NylasAPI.get(`${API_BASE}/auth/${code}`)
      .then(function (response) {
        setAuthState({ jwt: response.data });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const handleGoogleSignIn = async (data) => {
    try {
      setUiState({ loading: true });
      const response = await NylasAPI.post(`${API_BASE}/auth/google`, {
        code: data.code,
      });

      setAuthState({ jwt: response.data });
      history.push("/email");

      //logic to call the backend to send the code which will retrieve the access_token and referesh token to send to authorize then /connect/token
    } catch (error) {
    } finally {
      setUiState({ loading: false });
    }
  };

  return { handleGoogleSignIn, getJWT };
}
