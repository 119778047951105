import { useEffect, useRef, useState } from "react";
import GoogleButton from "react-google-button";
import { useAuthActions } from "../../recoil/actions/authActions";

const GOOGLE_SCOPES = [
  "openid",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/gmail.labels",
  "https://www.googleapis.com/auth/calendar",
  "https://www.googleapis.com/auth/gmail.send",
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/gmail.compose",
  "https://www.googleapis.com/auth/contacts",
  "https://mail.google.com/",
];

export default function GoogleSignIn() {
  const googleButtonRef = useRef();
  const [oAuthClient, setOAuthClient] = useState();
  const { handleGoogleSignIn } = useAuthActions();
  useEffect(() => {
    if (window.google) {
      const oAuth2Client = window.google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: GOOGLE_SCOPES.join(" "),
        access_type: "offline",
        ux_mode: "popup",
        callback: handleGoogleSignIn,
      });
      setOAuthClient(oAuth2Client);
    }
  }, []);

  return (
    <div ref={googleButtonRef} id="googleSignIn">
      <GoogleButton
        type="light"
        onClick={(e) => {
          oAuthClient.requestCode();
        }}
      ></GoogleButton>
    </div>
  );
}
