import { Box, Button, CircularProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import ContactCard from "../Components/ContactsUI/contactCard";
import ContactDialog from "../Components/ContactsUI/contactsDialog";

import AddIcon from "@material-ui/icons/Add";
import { useContactActions } from "../recoil/actions/contactActions";
import { contactState } from "../recoil/atom/contactState";
import withNavBar from "../hooks/withNavBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  inline: {
    display: "flex",
  },
  addContact: {
    backgroundColor: "#4169e1",
    width: "20%",
    margin: "10px",
    color: "#fff",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    color: "#4169e1",
  },
}));

function Contacts() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const contacts = useRecoilValue(contactState);
  const contactActions = useContactActions();
  const [open, setOpenDialog] = useState(false);
  const [updateContact, setUpdateContact] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await contactActions.getContacts(0);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>Contacts</h1>
      <Button
        className={classes.addContact}
        size="medium"
        variant="contained"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        <AddIcon />
      </Button>
      <Divider></Divider>
      <List className={classes.root}>
        {!loading ? (
          contacts
            .filter(
              (contact) => contact.given_name && contact.emails.length > 0
            )
            .map((contact, index) => {
              return (
                <>
                  <ListItem key={index.toString()}>
                    <ContactCard
                      index={index}
                      contact={contact}
                      updateContact={() => {
                        setUpdateContact(contact);
                        setOpenDialog(true);
                      }}
                    />
                  </ListItem>
                  <Divider></Divider>
                </>
              );
            })
        ) : (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
      </List>

      {open && (
        <ContactDialog
          open={open}
          contact={updateContact}
          onClose={() => {
            setOpenDialog(false);
            setUpdateContact(undefined);
          }}
        />
      )}
    </div>
  );
}

export default withNavBar(Contacts);
