import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NylasAPI from "../axios/axios-instance";

// State Object for Auth
import { Box, CircularProgress } from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import { authState } from "../recoil/atom/authState";
import { useAuthActions } from "../recoil/actions/authActions";

// function GetJWT(code) {
//   const setAuthState = useSetRecoilState(authState);

//   NylasAPI.get(`http://localhost:8000/auth/${code}`)
//     .then(function (response) {
//       setAuthState({ jwt: response.data });
//     })
//     .catch(function (error) {
//       // handle error
//       console.log(error);
//     });
// }

// function useQuery() {
//   const { search } = useLocation();
//   const authActions = useAuthActions();

//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

export default function Callback() {
  const { search } = useLocation();
  const authActions = useAuthActions();
  let query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    (async () => {
      try{
        await authActions.getJWT(query.get("code"))
      } catch (error) {
        console.log(error)
      }
    })();
  }, [])


  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </Box>
    </>
  );
}
