import { useSetRecoilState } from "recoil";
import NylasAPI from "../../axios/axios-instance";
import { calendarState } from "../atom/calendarState";

export function useCalendarActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/events`;
  const setCalendarState = useSetRecoilState(calendarState);

  async function getCalendars({ calendarId, starts_before, starts_after }) {
    try {
      const { data } = await NylasAPI.get(`${baseUrl}?`, {
        params: {
          ...(calendarId && { calendarId }),
          ...(starts_before && { starts_before }),
          ...(starts_after && { starts_after }),
        },
      });

      setCalendarState({
        calendar: data.calendar,
        events: data.events,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function createEvent({
    calendarId,
    subject,
    busy,
    participants,
    startTime,
    endTime,
  }) {
    try {
      const { data } = await NylasAPI.post(`${baseUrl}`, {
        calendarId,
        subject,
        busy,
        participants,
        startTime,
        endTime,
      });

      setCalendarState(({ events, calendar }) => ({
        calendar,
        events: [...events, data],
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteEvent(id) {
    try {
      await NylasAPI.delete(`${baseUrl}/${id}`);
      setCalendarState(({ events, calendar }) => ({
        calendar,
        events: events.filter((event) => event.id !== id),
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateEvent(
    id,
    { calendarId, subject, busy, participants, startTime, endTime }
  ) {
    try {
      const { data } = await NylasAPI.put(`${baseUrl}/${id}`, {
        calendarId,
        subject,
        busy,
        participants,
        startTime,
        endTime,
      });

      setCalendarState(({ events, calendar }) => ({
        calendar,
        events: [...events.filter((event) => event.id !== id), data],
      }));
    } catch (error) {
      console.log(error);
    }
  }
  return { getCalendars, createEvent, deleteEvent, updateEvent };
}
