import { Box, CircularProgress } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useMemo, useState } from "react";
import { useContactActions } from "../../recoil/actions/contactActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#4169E1",
  },
}));

export default function ContactCard({
  contact,
  index,
  updateContact,
  ...props
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const { deleteContact, getContacts } = useContactActions();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const deleteInfo = async (e) => {
    try {
      setLoading(true);
      await deleteContact(contact.id);
    } catch (error) {
      console.log(error);
    } finally {
      await getContacts();
      setLoading(false);
    }
  };

  const displayName = useMemo(
    () => contact.given_name || contact.surname,
    [contact]
  );

  const displayInitial = useMemo(() => {
    if (!contact.given_name) {
      return `JD${index}`;
    }

    const splitName = contact.given_name
      .split(" ")
      .map((word) => word.at(0))
      .join("");
    return splitName;
  }, [contact]);

  const displayEmail = useMemo(() => {
    return contact.emails.at(0).email;
  }, [contact]);

  return (
    <Card className={classes.root}>
      {!loading ? (
        <>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {displayInitial}
              </Avatar>
            }
            title={displayName}
            subheader={displayEmail}
          />
          <CardActions disableSpacing>
            <IconButton onClick={deleteInfo} aria-label="Delete contact">
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={updateContact} aria-label="edit">
              <EditIcon />
            </IconButton>
          </CardActions>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
}
