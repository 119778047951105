import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useCallback, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import NylasLoader from "../Components/LoadingUI/NylasLoader";
import GoogleSignIn from "../Components/OAuth/Google";
import { authState } from "../recoil/atom/authState";
import { uiState } from "../recoil/atom/uiState";

const NYLAS_CLIENT_ID = process.env.REACT_APP_NYLAS_CLIENT_ID;
const API_BASE = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
const auth_url = (email) =>
  `https://api.nylas.com/oauth/authorize?client_id=${NYLAS_CLIENT_ID}&redirect_uri=${API_BASE}/callback&response_type=code&scopes=email.read_only,calendar,contacts&login_hint=${email}`;

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    width: "100% !important",
  },
  emailInput: {
    width: "100%",
    margin: "10px",
  },
  errorSpan: {
    font: "500 14px/20px 'Poppins',sans-serif",
    color: "red",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  signIn: {
    font: "500 14px/20px 'Poppins',sans-serif",
    transition: "background-color 0.3s",
    width: "29%",
    padding: "11px 35px",
    backgroundColor: "#4169E1",
    color: "#fff",
    margin: "10px;",
    textDecoration: "none",
    "&:hover": {
      border: "1px solid #4169E1",
      backgroundColor: "#fff",
      color: "#4169E1",
    },
  },
}));

export default function Home() {
  const auth = useRecoilValue(authState);
  const { loading } = useRecoilValue(uiState);
  const [error, setError] = useState("");
  const setEmail = useSetRecoilState(authState);
  const styles = useStyles();

  const onSubmit = useCallback(
    (e) => {
      if (!auth.email) {
        return setError("Please enter an email");
      }
      window.location.href = auth_url(auth.email);
    },
    [auth.email]
  );

  return (
    <div className={styles.mainDiv}>
      <Container className={styles.container}>
        {loading ? (
          <NylasLoader></NylasLoader>
        ) : (
          <Card style={{ width: "70%" }} raised>
            <CardContent className={styles.cardContent}>
              <img
                className={styles.logo}
                src="https://www.nylas.com/wp-content/uploads/main-logo.svg"
              ></img>
              <TextField
                className={styles.emailInput}
                id="filled-number"
                label="Email"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                onChange={(e) => {
                  setError("");
                  setEmail({ email: e.target.value });
                }}
              />
              {error && <span className={styles.errorSpan}>{error}</span>}

              <Button
                className={styles.signIn}
                onClick={onSubmit}
                variant="contained"
              >
                Login
              </Button>
              <GoogleSignIn />
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
}
