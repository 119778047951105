import { useState } from "react";

export default function useTextValue(initialVal) {
  const [text, setText] = useState(initialVal);

  const onChange = (e) => {
    setText(e.target.value);
  };

  return [text, onChange];
}
