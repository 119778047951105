import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

export default function NylasDialog({
  open,
  onClose,
  title,
  update = false,
  DeleteAction = undefined,
  onSubmit,
  ...props
}) {
  return (
    <>
      {open && (
        <Dialog
          keepMounted
          open={open}
          onClose={onClose}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{props.children}</DialogContent>
          <DialogActions>
            <Button variant={"outlined"} onClick={onSubmit}>
              {update ? "Update" : "Create"}
            </Button>
            {DeleteAction && DeleteAction}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
