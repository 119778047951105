import { makeStyles } from "@material-ui/core";
import { useCallback } from "react";
import { Link } from "react-router-dom";

// Atoms
import { useRecoilValue, useResetRecoilState } from "recoil";
import { authState } from "../../recoil/atom/authState";
import { removeSessionToken } from "../../session";

const useStyles = makeStyles((theme) => ({
  mainHeader: {
    position: "relative",
    left: "0",
    top: "0",
    width: "90%",
    padding: "20px 40px",
    backgroundColor: "transparent",
    transition: "background-color 0.5s ease",
    zIndex: "200",
    margin: "0 auto",
  },
  mainHeaderHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainLogoHolder: {
    display: "block",
    position: "relative",
    zIndex: 20,
    width: "auto",
  },
  mainNavHolder: {
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
  },
  linkItem: {
    font: "500 14px/20px 'Poppins',sans-serif",
    transition: "color 0.3s",
    padding: "11px 20px",
    borderRadius: "10px",
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#4169E1",
    },
  },
  linkItemAuth: {
    font: "500 14px/20px 'Poppins',sans-serif",
    transition: "background-color 0.3s",
    padding: "11px 35px",
    border: "1px solid #fff",
    backgroundColor: "#4169E1",
    borderRadius: "10px",
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      border: "1px solid #4169E1",
      backgroundColor: "#fff",
      color: "#4169E1",
    },
  },
}));

function Navbar() {
  const classes = useStyles();

  const loginState = useRecoilValue(authState);
  const resetLogin = useResetRecoilState(authState);

  const onLogOut = useCallback(
    (e) => {
      resetLogin();
      removeSessionToken();
    },
    [authState]
  );

  return (
    <header className={classes.mainHeader}>
      <div className={classes.mainHeaderHolder}>
        <strong className={classes.mainLogoHolder}>
          <Link to="/">
            <img
              src="https://www.nylas.com/wp-content/uploads/main-logo.svg"
              alt="Nylas Logo - Link to Home"
            />
          </Link>
        </strong>
        <div className={classes.mainNavHolder}>
          {loginState.jwt && (
            <>
              <Link to="/email" className={classes.linkItem}>
                Email
              </Link>
              <Link to="/calendar" className={classes.linkItem}>
                Calendar
              </Link>
              <Link to="/contacts" className={classes.linkItem}>
                Contacts
              </Link>
              <Link to="/scheduler" className={classes.linkItem}>
                Scheduler
              </Link>
              <Link onClick={onLogOut} to="/" className={classes.linkItemAuth}>
                Logout
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
