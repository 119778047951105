import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "./App.css";
import Calendar from "./Pages/Calendar";
import Callback from "./Pages/Callback";
import Contacts from "./Pages/Contacts";
import Email from "./Pages/Email";
import Home from "./Pages/Home";
import Scheduler from "./Pages/Scheduler";
import { authState } from "./recoil/atom/authState";
import { getSessionToken } from "./session";

// import './axios/axios-instance'

function App() {
  const auth = useRecoilValue(authState);
  const setAuthState = useSetRecoilState(authState);

  useEffect(() => {
    const token = getSessionToken();
    if (token) {
      setAuthState({ jwt: token });
    } else {
      setAuthState({});
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/calendar" element={<Calendar />} />
        <Route exact path="/email" element={<Email />} />
        <Route exact path="/contacts" element={<Contacts />} />
        <Route exact path="/scheduler" element={<Scheduler />} />
        <Route
          path="/callback"
          element={!auth.jwt ? <Callback /> : <Navigate to="/email" />}
        />
      </Routes>
    </div>
  );
}

export default App;
